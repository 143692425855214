function QuickWishlist() {
    var self = this;

    this.wishlistAddButtons = document.querySelectorAll('.js-wishlist-add');
    this.$quickBasket = document.querySelector('.js-quick-wishlist');
    this.$quickBasketWrap = document.querySelector('.js-quick-wishlist__wrap');
    this.$count = document.querySelector(
        '.js-wishlist-count .header-action__count'
    );

    this.clearDuration = 300;
    this.clearDelay = 200;
    this.timeout = 6000;
    this.clear;

    this.init = () => {
        Soho.plugins.delegate(document, 'click', '.js-wishlist-add', (e) => this.addToWishlist(e));

        const basketItems = document.querySelector('.basket-table tbody');

        if (basketItems) {
            basketItems.addEventListener('click', (e) => {
                const link = e.target.closest('.js-wishlist-move-link');

                if (!link) {
                    return;
                }

                e.preventDefault();

                this.add(
                    link.href,
                    'GET',
                    new URLSearchParams(new FormData()).toString(),
                    link,
                    link.dataset.sku,
                    (response) => {
                        const data = response.payload;
                        if (!data) {
                            return;
                        }

                        const basketElem = document.querySelector('.basket');
                        const sidebar = document.querySelector('.basket-sidebar');
                        const subtotalMobileElement = basketElem.querySelector('.basket__main .basket-mobile-total');
                        const subtotalMobilePriceElement = subtotalMobileElement.querySelector('.basket-mobile-total__price');
                        const wishlistCount = document.querySelector('.js-wishlist-count .header-action__count');
                        const basketCount = document.querySelector('.js-basket-count .header-action__count');
                        const deliveryElement = sidebar.querySelector('.basket-delivery');

                        if (data.count) {
                            basket.items.content = data.html;
                            basket.items.dispatchEvent(new Event(Basket.Events.ITEM_REMOVED), data);
                        } else {
                            const basketTitle = document.querySelector('.basket__title');
                            const basketMembership = document.querySelector('.basket-membership');
                            const basketSidebar = document.querySelector('.basket__sidebar');
                            const basketPromo = document.querySelector('.basket__promo');

                            basketTitle.innerHTML = data.page_title;

                            const div = document.createElement("div");
                            div.innerHTML = data.empty_basket_html;
                            basketMembership.parentNode.replaceChild(div, basketMembership);

                            basketItems.parentElement.remove();
                            basketSidebar.remove();
                            basketPromo.remove();

                            if (window.dataLayer) {
                                Soho.GTM.trackRemoveBasketProducts([data.item], data.trackingCurrency);
                            }
                        }

                        if (data.count === 0) {
                            subtotalMobileElement.style.display = 'none';
                        } else {
                            subtotalMobilePriceElement.innerHTML = data.subtotal;
                        }
            
                        basketCount.innerHTML = data.count > 0 ? data.count : '';
                        wishlistCount.innerHTML = data.wishlist_count > 0 ? data.wishlist_count : '';
                        deliveryElement.innerHTML = data.delivery;
                    }
                );
            });
        }
    };

    this.addToWishlist = (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();
        const button = e.target.closest('button');

        if (button) {
            if (button.classList.contains('item-in-wishlist')) {
                return;
            }

            const form = button.closest('form');
            const sku = form.dataset.sku;

            this.add(
                form.getAttribute('data-wishlist-action'),
                form.getAttribute('method'),
                new URLSearchParams(new FormData(form)).toString(),
                button,
                sku
            );
        }
    }

    this.getPairedButtons = function(button) {
        const buttons = [button]

        if (button.classList.contains('product-detail__wishlist')) {
            const sticky = document.querySelector('.product-sticky-cta__wishlist');
            if (sticky) {
                buttons.push(sticky);
            }

        } else if (button.classList.contains('product-sticky-cta__wishlist')) {
            const normal = document.querySelector('.product-detail__wishlist');
            if (normal) {
                buttons.push(normal);
            }
        }

        return buttons
    }

    this.add = function (url, type, data, button, sku, onSuccess) {
        $.ajax({
            url: url,
            type: type,
            data: data,
            success: function (data) {
                if (data.result) {

                    // Check if there is a paired button and update each
                    self.getPairedButtons(button).forEach((el) => {
                        const buttonText = el.querySelector('span');

                        if (buttonText) {
                            buttonText.innerText = 'Added to Wishlist';
                        }

                        el.classList.add('item-in-wishlist');
                    });

                    // Update the quick basket and the basket count
                    self.update(data.payload.wishlist_count);

                    // Add the html to the quick basket
                    self.$quickBasketWrap.innerHTML = data.payload.wishlist_notification;

                    // Define the new quick basket container and close elements
                    self.$container = document.querySelector(
                        '.js-quick-wishlist__container'
                    );

                    self.$close = document.querySelector('.js-quick-wishlist__close');

                    // Assign click events to new elements
                    self.$close.onclick = self.deactivate.bind(self);
                    self.$quickBasket.onclick = self.deactivate.bind(self);

                    self.$container.onclick = function (e) {
                        e.stopPropagation();
                    };

                    self.trackEvent('Add', sku, button);

                    if (typeof onSuccess === 'function') {
                        onSuccess(data);
                    }
                }
            },
        });
    };

    this.update = function (count) {
        this.activate();
        this.$count.innerHTML = count;
        this.clear = setTimeout(this.deactivate.bind(this), this.timeout);
    };

    this.activate = function () {
        this.$quickBasket.classList.add('quick-wishlist--activating');

        setTimeout(function () {
            self.$quickBasket.classList.add('quick-wishlist--active');
            // self.$close.focus();
        }, 200);
    };

    this.deactivate = function () {
        this.$quickBasket.classList.remove('quick-wishlist--active');
        setTimeout(function () {
            self.$quickBasket.classList.remove('quick-wishlist--activating');
            clearTimeout(self.clear);
        }, this.clearDuration + this.clearDelay);
    };

    /**
     * Track the interaction into GTM
     *
     * @param {String} action action taken
     * @param {String|Number} sku product SKU
     * @param {Element} button button clicked
     */
    this.trackEvent = function (action, sku, button) {
        const location = getButtonLocation(button)

        if (window.dataLayer) {
            dataLayer.push({
                'event': 'd3rEvent',
                'd3rEvent': {
                    'category': 'Wishlist',
                    'action': `${action} (${location})`.trim(),
                    'label': sku,
                },
            });
        }
    }

    /**
     * Try and derive a location - one of PDP, PLP, Carousel, or null - from the clicked element
     *
     * @param {Element} buttonElement
     */
    const getButtonLocation = (buttonElement) => {

        if (buttonElement.dataset.wishlistEventLocation) {
            return buttonElement.dataset.wishlistEventLocation;
        }

        const pdp = document.querySelector('.product-detail__right');

        if (pdp && pdp.contains(buttonElement)) {
            return 'PDP'
        }

        const plp = document.querySelector('.listing');

        if (plp && plp.contains(buttonElement)) {
            return 'PLP'
        }

        const carousels = document.querySelectorAll('.products-list');

        for (let i = 0; i < carousels.length; i++) {
            if (carousels[i].contains(buttonElement)) {
                return 'Carousel'
            }
        }

        return ''
    }
}
